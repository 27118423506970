import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineGlobeIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M12 2C6.4865 2 2 6.4865 2 12s4.4865 10 10 10h.0901C17.5586 21.9459 22 17.4775 22 12S17.5135 2 12 2Zm.9189 2.2432c.3243.3694.6577.919.946 1.6397.5135 1.2793.9099 3.063.991 5.2612H9.1441c.0811-2.1982.4775-3.991.991-5.2612.2883-.7207.6217-1.2703.946-1.6397.3333-.3693.6486-.5495.9189-.5495.2703 0 .5856.1802.9189.5495Zm3.6306 6.91c-.081-2.1352-.4684-4.2703-1.1351-5.937-.1351-.3423-.2973-.6576-.4594-.964 2.8738 1.0991 4.9819 3.7388 5.3063 6.901h-3.7118Zm-7.964-5.946C7.91 6.874 7.5316 9.009 7.4506 11.1441h-3.712C4.0631 7.973 6.1802 5.3333 9.045 4.2432a11.9656 11.9656 0 0 0-.4594.964Zm-1.135 7.6396c.081 2.1352.4684 4.2793 1.135 5.946.1352.3423.2884.6577.4505.9549-2.8648-1.0991-4.982-3.7387-5.3063-6.9009h3.7208Zm3.6306 6.91c-.3243-.3694-.6577-.919-.946-1.6397-.5135-1.2793-.9099-3.063-.991-5.2612h5.7118c-.0811 2.1982-.4775 3.9909-.991 5.2612-.2883.7207-.6126 1.2703-.946 1.6397-.3333.3693-.6486.5495-.9189.5495-.2703 0-.5856-.1802-.9189-.5495Zm3.8739 0c.1621-.2973.3153-.6217.4594-.964.6757-1.6667 1.0541-3.8018 1.1351-5.9369h3.7118c-.3244 3.1621-2.4325 5.8018-5.3063 6.9009Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineGlobeIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidSearchIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21.7 20.3 18 16.6c3.1-3.9 2.5-9.6-1.4-12.7C12.7.8 7 1.5 3.9 5.3.8 9.2 1.5 14.9 5.3 18c3.3 2.6 8 2.6 11.3 0l3.7 3.7c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4ZM11 18c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7Z"/>
  </SvgIcon>
}

export default SolidSearchIcon

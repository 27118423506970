import cn from 'clsx'
import Clickable from 'components/Common/Clickable'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const DestinationItem = styled(Clickable)`
  height: ${rem(62)};
  padding: ${rem(12)};
  display: flex;
  align-items: center;
  gap: ${rem(12)};
  background-color: transparent;
  transition: background-color 0.2s;
  border-radius: ${props => props.theme.borderRadius.S};

  &.selected {
    background-color: ${props => props.theme.palette.brand.primary.lightest};
  }

  ${mediaHoverable} {
    &:hover {
      background-color: ${props => props.theme.palette.neutral.default.seven};
    }
  }
  &:active {
    background-color: ${props => props.theme.palette.neutral.default.seven};
  }

  &:focus {
    box-shadow: var(${KEYBOARD_MODE_CSS_VAR}, 0 0 0 2px ${props => props.theme.palette.brand.primary.normal} inset);
  }

`

interface Props {
  icon: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function ExperiencesPageLocationModalItem(props: Props) {
  const { icon, children, className, onClick, selected, ...rest } = props

  return <DestinationItem
    {...rest}
    onClick={onClick}
    className={cn(
      className,
      { selected },
    )}
  >
    {icon}
    <BodyText variant="medium">{children}</BodyText>
  </DestinationItem>
}

export default ExperiencesPageLocationModalItem

import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineLocationArrowIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21.15 2.86a2.8901 2.8901 0 0 0-3-.71L4 6.88a2.9 2.9 0 0 0-.12 5.47l5.24 2a.929.929 0 0 1 .53.52l2 5.25A2.8703 2.8703 0 0 0 14.36 22h.07a2.88 2.88 0 0 0 2.69-2l4.73-14.17a2.8902 2.8902 0 0 0-.7-2.97ZM20 5.2l-4.78 14.18a.8798.8798 0 0 1-.84.62.9196.9196 0 0 1-.87-.58l-2-5.25a2.9095 2.9095 0 0 0-1.67-1.68l-5.25-2A.9.9 0 0 1 4 9.62a.88.88 0 0 1 .62-.84L18.8 4.05A.91.91 0 0 1 20 5.2Z"/>
  </SvgIcon>
}

export default LineLocationArrowIcon
